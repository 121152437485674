import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/Users/timothykrell/Code/superfarm/blackhole/blackhole-dex-ui-shared/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_rgjgsxitbwnoqw7n24dhvdi42m/node_modules/next/dist/client/components/render-from-template-context.js");
